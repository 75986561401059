import React, { useState, useRef, useEffect } from 'react'


function OpenTickets() {
  const [openTckt, setOpenTckt] = useState([])

  const [zip, setZip] = useState("")
  const [id, setId] = useState("")
  const [edit, setEdit] = useState({});
  const [mobile, setMobile] = useState(0)
  const [searchRes, setSearchRes] = useState([])
  const [showSearch, setShowSearch] = useState(false)
  const [sort, setSort] = useState(false)
  const [EmpDetails, setEmpDetails] = useState([])
  // const [assingedTo, setAssignedTo] = useState("johnDoe")
  const [tcktNo, setTcktNo] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  const hostname = process.env.REACT_APP_SERVER

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`

  const refClose = useRef(null);
  // console.log(OpenTckt);
  const set = (id, Zip, tcktNo, Phone) => { setId(id); setZip(Zip); setTcktNo(tcktNo); setPhoneNo(Phone) }

  //                FETCHING EMPLOYEE
  const fetchEmp = async () => {
    const url = `${hostname}/api/Admin/Employee/fetchEmp`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const empDetails = await response.json()
    // console.log(empDetails);
    setEmpDetails(empDetails)

  }
  useEffect(() => { fetchEmp() }, [])


  //                 SORT OPEN TICKETS
  const sortOT = async () => {
    const url = `${hostname}/api/Admin/sortOT`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Open = await response.json()
    setOpenTckt(Open)
    setSort(true);
  }

  const Cancelsorting = () => { setSort(false); fetchOT() }

  //                                            ASSINGING EMPLOYEE TO A TICKET

  const AssignEmployee = async () => {
    const id = edit.empAssign
    const url =`${hostname}/api/Admin/Employee/findEmployee/${id}`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
    const empDetails = await response.json()
    const name = empDetails.username
    await addDetails(name)
    await transferData()
    window.location.reload();
    refClose.current.click();
    await sendSMS(name);

  }

  // Adding details to Open Ticket

  const addDetails = async (name) => {
    const url = `${hostname}/api/Admin/empAssign/${id}`
    // const url = `http://localhost:5000/api/Admin/empAssign/${id}`
    await fetch(url,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ empAssign: edit.empAssign, Status: "Pending", ptDate: date, assingedTo: name })
      });
  }

  // Transfering Open Ticket to Pending Ticket

  const transferData = async () => {
    const url = `${hostname}/api/Admin/transfer/${id}`
    // const url = `http://localhost:5000/api/Admin/transfer/${id}`
    await fetch(url);

  }

  //  Sending SMS to user after Assinging Employee 

  const sendSMS = async (name) => {
    const url = `${hostname}/api/Employee/addTicketsms`
    // const url = "http://localhost:5000/api/Employee/addTicketsms"
    await fetch(url, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ "number": phoneNo, "TicketNo": tcktNo, "Representative": name })
    });

  }

  const onChange = (e) => { setEdit({ empAssign: e.target.value }) }

  const searchTicket = async () => {
    setShowSearch(true)
    const url =`${hostname}/api/Admin/findOTByPhoneNo?PhoneNo=${mobile}`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const result = await response.json()
    setSearchRes(result);
  }

  //                 FETCHING OPEN TICKETS
  const fetchOT = async () => {
    const url = `${hostname}/api/Admin/fetchOT`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Open = await response.json()
    setOpenTckt(Open)
    // console.log(Open);
  }




  const onChang = (e) => {
    setMobile(e.target.value)
  }
  const handleCancel = () => {
    setShowSearch(false)
  }
  useEffect(() => { fetchOT() }, [])

  return (

    <>

      {/* MODAL FOR ASSIGNING EMPLOYEE FOR PARTICULAR TICKET */}

      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Employee Details</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="form-outline mb-4">
                <select className="form-select" onChange={onChange} aria-label="Default select example">
                  <option selected>Assign Employee</option>
                  {EmpDetails.map((element) => {
                    if (element.Zip === zip)
                      return <option name="empAssign" value={element._id} > Name :{element.username}</option>
                  })
                  }
                </select>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" ref={refClose} className="btn form-btn" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn form-btn" onClick={AssignEmployee} >Save changes</button>
            </div>
          </div>
        </div>
      </div>

      {/* LIST OF OPEN TICKETS */}
      <div>
        <input type="text" placeholder='Mobile Number' id='Search' className='invoice-input mx-1' onChange={onChang} />
        <button className='btn btn-primary btn-sm mx-2 my-1' style={{ backgroundColor: " var(--primary-color)" }} onClick={searchTicket} >Search</button>
        {showSearch === true ? <button className='btn btn-primary btn-sm mx-2' style={{ backgroundColor: " var(--primary-color)" }} onClick={handleCancel} >Cancel</button> : <button className='btn btn-primary btn-sm mx-2 my-1' style={{ backgroundColor: " var(--primary-color)" }} onClick={sortOT} >Sort By Pin</button>}
        {sort === true ? <button className='btn btn-primary btn-sm mx-2' style={{ backgroundColor: " var(--primary-color)" }} onClick={Cancelsorting} >Cancel</button> : <></>}
      </div>
      <div className='container-ticket'>
        <table className="table table-bordered table-striped ">
          <thead>
            <tr>
              <th scope="col" className='text-center'>Ticket Number</th>
              <th scope="col" className='text-center'>Open Ticket Date</th>
              <th scope="col" className='text-center'>Full Name</th>
              <th scope="col" className='text-center'>Complaint Description</th>
              <th scope="col" className='text-center'>Email</th>
              <th scope="col" className='text-center'>Contact Number</th>
              <th scope="col" className='text-center'>Address</th>
              <th scope="col" className='text-center'>Landmark</th>
              <th scope="col" className='text-center'>City</th>
              <th scope="col" className='text-center'>State</th>
              <th scope="col" className='text-center'>Zip</th>
              <th scope="col" className='text-center'>Status</th>
              <th scope="col" className='text-center'>Assign</th>
            </tr>
          </thead>
          <tbody>
            {showSearch === false ? openTckt.map((element, index) => {
              return <tr key={index} >
                <th scope="row" className='text-center'>{element.TicketNo}</th>
                <th scope="row" className='text-center'>{element.otDate}</th>
                <td className='text-center'>{element.Name}</td>
                <td className='text-center'>{element.ComplainType}</td>
                <td className='text-center'>{element.Email}</td>
                <td className='text-center'>{element.PhoneNo}</td>
                <td className='text-center'>{element.Address}</td>
                <td className='text-center'>{element.Landmark}</td>
                <td className='text-center'>{element.City}</td>
                <td className='text-center'>{element.State}</td>
                <td className='text-center'>{element.Zip}</td>

                <td className='text-center'>
                  <button className="btn btn-sm" style={{ backgroundColor: " var(--cardOT-clr)", borderRadius: "16px", color: "white" }} type="button">{element.Status}</button>
                </td>
                <td className='text-center'>
                  <button type="button" className="btn btn-primary btn-sm" onClick={() => set(element._id, element.Zip, element.TicketNo, element.PhoneNo)} data-bs-toggle="modal" style={{ borderRadius: "16px" }} data-bs-target="#exampleModal">Assign</button>
                </td>
              </tr>
            }) : searchRes.map((element, index) => {
              return <tr key={index} >
                <th scope="row" className='text-center'>{element.TicketNo}</th>
                <th scope="row" className='text-center'>{element.otDate}</th>
                <td className='text-center'>{element.Name}</td>
                <td className='text-center'>{element.ComplainType}</td>
                <td className='text-center'>{element.Email}</td>
                <td className='text-center'>{element.PhoneNo}</td>
                <td className='text-center'>{element.Address}</td>
                <td className='text-center'>{element.Landmark}</td>
                <td className='text-center'>{element.City}</td>
                <td className='text-center'>{element.State}</td>
                <td className='text-center'>{element.Zip}</td>
                <td className='text-center'><button className="btn btn-sm" style={{ backgroundColor: " var(--cardOT-clr)", backgroundColor: "red", borderRadius: "16px", color: "white" }} >{element.Status}</button>
                </td>
                <td className='text-center'>
                  <button className="btn btn-primary btn-sm" onClick={() => set(element._id, element.Zip, element.TicketNo, element.PhoneNo)} data-bs-toggle="modal" style={{ borderRadius: "16px" }} data-bs-target="#exampleModal">Assign</button>
                </td>
              </tr>
            })
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default OpenTickets