import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { actionCreater } from '../../../State/index'
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom'

function ClosedTickets() {
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState(0)
  const [searchRes, setSearchRes] = useState([])
  const [showSearch, setShowSearch] = useState(false)
  const [closeTckt, setCloseTckt] = useState([])
  const history = useNavigate();
  const [sort, setSort] = useState(false)
  const hostname = process.env.REACT_APP_SERVER


  //                 SORT CLOSE TICKETS
  const sortCT = async () => {
    const url = `${hostname}/api/Admin/sortCls`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Close = await response.json()
    setCloseTckt(Close)
    setSort(true);
  }

  const Cancelsorting = async () => {
    setSort(false);
    const url = `${hostname}/api/Admin/fetchCls`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Close = await response.json()
    setCloseTckt(Close)
  }


  const Prnt = async (TicketNo) => {
    const url = `${hostname}/api/Employee/prnt?TicketNo=${TicketNo}`
    const response = await fetch(url)
    const Prnt = await response.json()
    dispatch(actionCreater.PrintInvoice(Prnt))
    history("/Admin/ClosedTickets/Invoice")
  }



  const searchTicket = async () => {
    setShowSearch(true)
    const url = `${hostname}/api/Admin/findCTByPhoneNo?PhoneNo=${mobile}`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const result = await response.json()
    setSearchRes(result);
  }

  //                 FETCHING CLOSED TICKETS
  const fetchCls = async () => {
    const url = `${hostname}/api/Admin/fetchCls`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      }
    })
    const Close = await response.json()
    // console.log(Close);
    setCloseTckt(Close.reverse())
  }

  const onChang = (e) => { setMobile(e.target.value) }

  const handleCancel = () => { setShowSearch(false) }

  useEffect(() => { fetchCls() }, [])

  return (
    <div>
      <div>
        <input type="text" placeholder='Mobile Number' id="Search" className='invoice-input mx-1' onChange={onChang} />
        <button className='btn btn-primary btn-sm mx-2 my-1' style={{ backgroundColor: " var(--primary-color)" }} onClick={searchTicket} >Search</button>
        {showSearch === true ? <button className='btn btn-primary btn-sm mx-2' style={{ backgroundColor: " var(--primary-color)" }} onClick={handleCancel} >Cancel</button> : <button className='btn btn-primary btn-sm mx-2 my-1' style={{ backgroundColor: " var(--primary-color)" }} onClick={sortCT} >Sort By Pin</button>}
        {sort === true ? <button className='btn btn-primary btn-sm mx-2' style={{ backgroundColor: " var(--primary-color)" }} onClick={Cancelsorting} >Cancel</button> : <></>}
      </div>
      <div className='container-ticket'>
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col" className='text-center'>Ticket Number</th>
              <th scope="col" className='text-center'>Open Ticket Date</th>
              <th scope="col" className='text-center'>Pending Ticket Date</th>
              <th scope="col" className='text-center'>Closing Ticket Date</th>
              <th scope="col" className='text-center'>Full Name</th>
              <th scope="col" className='text-center'>Complaint Description</th>
              <th scope="col" className='text-center'>Email</th>
              <th scope="col" className='text-center'>Contact Number</th>
              <th scope="col" className='text-center'>City</th>
              {/* <th scope="col" className='text-center'>State</th> */}
              <th scope="col" className='text-center'>Zip</th>
              <th scope="col" className='text-center'>Status</th>
              <th scope="col" className='text-center'>Invoice</th>

            </tr>
          </thead>
          <tbody>
            {showSearch === false ? closeTckt.map((element, index) => {
              return <tr key={index} >
                <th scope="row" className='text-center'>{element.TicketNo}</th>
                <th scope="row" className='text-center'>{element.otDate}</th>
                <th scope="row" className='text-center'>{element.ptDate}</th>
                <th scope="row" className='text-center'>{element.ctDate}</th>
                <td className='text-center'>{element.Name}</td>
                <td className='text-center'>{element.ComplainType}</td>
                <td className='text-center'>{element.Email}</td>
                <td className='text-center'>{element.PhoneNo}</td>
                {/* <td className='text-center'>{element.Address}</td>
                <td className='text-center'>{element.Landmark}</td> */}
                <td className='text-center'>{element.City}</td>
                {/* <td className='text-center'>{element.State}</td> */}
                <td className='text-center'>{element.Zip}</td>
                {/* <td className='text-center'>{element.Country}</td> */}
                <td className='text-center'><button className="btn  btn-sm" style={{ backgroundColor : "var(--cardCT-clr)" , borderRadius: "16px", color: "white" }} type="button">{element.Status}</button></td>
                <td className='text-center'>
                  <button className="btn btn-primary btn-sm" onClick={() => Prnt(element.TicketNo)} style={{ borderRadius: "16px" }} type="button">Invoice</button>
                </td>
              </tr>
            }) : searchRes.map((element, index) => {
              return <tr key={index} >
                <th scope="row" className='text-center'>{element.TicketNo}</th>
                <th scope="row" className='text-center'>{element.otDate}</th>
                <th scope="row" className='text-center'>{element.ptDate}</th>
                <th scope="row" className='text-center'>{element.ctDate}</th>
                <td className='text-center'>{element.Name}</td>
                <td className='text-center'>{element.ComplainType}</td>
                <td className='text-center'>{element.Email}</td>
                <td className='text-center'>{element.PhoneNo}</td>
                {/* <td className='text-center'>{element.Address}</td> */}
                {/* <td className='text-center'>{element.Landmark}</td> */}
                <td className='text-center'>{element.City}</td>
                {/* <td className='text-center'>{element.State}</td> */}
                <td className='text-center'>{element.Zip}</td>
                {/* <td className='text-center'>{element.Country}</td> */}
                <td className='text-center'><button className="btn btn-sm" style={{  backgroundColor : "var(--cardCT-clr)",borderRadius: "16px", color: "white" }} type="button">{element.Status}</button></td>
                <td className='text-center'>
                  <button className="btn btn-primary btn-sm" onClick={() => Prnt(element.TicketNo)} style={{ borderRadius: "16px" }} type="button">Invoice</button>
                </td>
              </tr>
            })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ClosedTickets